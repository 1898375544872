//
// local settings
//

// ratchet specific colors
$colors: map-extend($colors, (
    gradient: (
      base: linear-gradient(180deg, color(blue) 0%, rgba(197, 166, 199, 1) 100%),
      masters: linear-gradient(to right, #bfac73 0%, #dbd19d 100%),
    ),
    rank-pro-1: (
      base: #00a8e1,
    ),
    rank-pro-2: (
      base: #f7cd56,
    ),
    rank-pro-3: (
      base: #c6590e,
    ),
    rank-pro-4: (
      base: #427954,
    ),
    rank-pro-5: (
      base: #194c71,
    ),
    rank-pro-6: (
      base: #5e3a70,
    ),
    rank-pro-7: (
      base: #84634a,
    ),
    rank-pro-8: (
      base: #7c192f,
    ),
    rank-pro-9: (
      base: #303c42,
    ),
    rank-master: (
      base: #bfac73,
    ),
));

// ratchet unbounded dots
$widths: map-extend($widths, (
    unbounded-dot: 20px,
));

// market list in sprite order
$market-list: at au be ca cn de do es fr hk ie it jp mx my nl nz ph sg th tw uk us;

// overwrite which cuts of proxima fonts to include
$proxima-font-faces: (
  300: ('normal'),
  400: ('normal'),
  600: ('normal'),
  700: ('normal'),
);

// ratchet font sizes
$responsive-font-sizes: map-extend($responsive-font-sizes, (
    marquee: (64px, 96px, 112px, 144px, 200px),
));

// ranks
$distributor-ranks: pro-1 pro-2 pro-3 pro-4 pro-5 pro-6 pro-7 pro-8 pro-9;

// ratchet specific z-indexes
$z-indexes: map-extend($z-indexes, (
    product-header: 10,
    distributor-bot: 41, // needs to be higher than the product header
    mobile-nav: 17,
    primary-nav: 18,
    primary-nav-open-sub-menu: 19,
    tool-tip: 61,
    modal: 70,
));
