//
// core required mixins, alphabetical
//

// generate media queries for targeting a min-aspect ratio
@mixin aspect-ratio-min($horizontal, $vertical) {
  @media only screen and (min-aspect-ratio: $horizontal #{'/'} $vertical) {
    @content;
  }
}

// generate media queries for targeting a max-aspect ratio
@mixin aspect-ratio-max($horizontal, $vertical) {
  @media only screen and (max-aspect-ratio: $horizontal #{'/'} $vertical) {
    @content;
  }
}

// background image maybe webp
@mixin background-image-maybe-webp($src) {
  .webp & {
    background-image: url($src + '.webp');
  }
  .no-webp & {
    background-image: url($src);
  }
}

// generate media queries for targeting widths
@mixin breakpoint($min-width, $max-width: null, $use-named-widths: true) {
  @if ($use-named-widths) {
    $min-width: width($min-width);
    @if ($max-width) {
      $max-width: width($max-width);
    }
  }
  @if ($max-width) {
    $max-width: $max-width - 1;
    @media only screen and (min-width: $min-width) and (max-width: $max-width) {
      @content;
    }
  }
  @else {
    @media only screen and (min-width: $min-width) {
      @content;
    }
  }
}

@mixin btn-colors($color-map) {
  // configurable options to be used with this mixin
  // text
  // text-hover
  // text-active
  // bg-color
  // bg-color-hover
  // bg-color-active
  @if ( $color-map ) {
    // change background color
    @if map-get($color-map, bg-color) {
      &::before {
        background-color: map-get($color-map, bg-color);
      }
    }
    // change background color on active
    @if map-get($color-map, bg-color-active) {
      &:active {
        &::before {
          background-color: map-get($color-map, bg-color-active);
        }
      }
    }
    // if an active state isn't set, utilize default color darkened
    @else {
      $bg-color-active: map-get($color-map, bg-color);
      &:active {
        &::before {
          background-color: darken($bg-color-active, 20%);
        }
      }
    }
    // change background color on focus
    @if map-get($color-map, bg-color-focus) {
      &:focus {
        &::before {
          background-color: map-get($color-map, bg-color-focus);
        }
      }
    }
    // if a focus state isn't set, utilize default color darkened
    @else {
      $bg-color-focus: map-get($color-map, bg-color);
      &:focus {
        &::before {
          background-color: darken($bg-color-focus, 10%);
        }
      }
    }
    // change background color on hover
    @if map-get($color-map, bg-color-hover) {
      &:hover {
        &::before {
          background-color: map-get($color-map, bg-color-hover);
        }
      }
    }
    // change text color
    @if map-get($color-map, text) {
      color: map-get($color-map, text);
    }
    // change text color on active
    @if map-get($color-map, text-active) {
      &:active {
        color: map-get($color-map, text-active);
      }
    }
    // change text color on hover
    @if map-get($color-map, text-hover) {
      &:hover {
        color: map-get($color-map, text-hover);
      }
    }
  }
  @else {
    @warn 'Oops, you didn\'t provide a btn-color parameter';
  }
}

// constrain proportions
@mixin constrain-proportions($width, $height-percent) {
  display: block;
  width: $width;
  &::before {
    content: '';
    display: block;
    padding-top: $height-percent;
  }
}

@mixin focus-default-styles {
  outline-color: Highlight;
  outline-style: auto;
  outline-width: 5px;
  z-index: 2;
}

// generate a @font-face rule
@mixin font-face($family, $path, $weight: 400, $style: normal, $exts: eot woff2 woff ttf svg) {
  $ext-mods: ( eot: '?', svg: '#' + str-replace($family, ' ', '') );
  $formats: ( otf: 'opentype', ttf: 'truetype' );
  $src: null;
  @each $ext in $exts {
    $ext-mod: if(map-has-key($ext-mods, $ext), $ext + map-get($ext-mods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + '.' + $ext-mod)) format(quote($format)), comma);
  }
  @font-face {
    font-family: quote($family);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

// turn font-smoothing on or off
@mixin font-smoothing($enable: true) {
  @if ($enable) {
    // disable all vendor prefix errors, we know what's up
    // sass-lint:disable-block no-vendor-prefixes
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
  @else {
    // disable all vendor prefix errors, we know what's up
    // sass-lint:disable-block no-vendor-prefixes
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

// generate media query for targeting landscape orientations
@mixin landscape {
  @media only screen and (orientation: landscape) {
    @content;
  }
}

// generate styles to match gutter size to $properties
@mixin match-gutter-size($properties, $scale: 1) {
  @each $property in $properties {
    #{$property}: width(gutter-s) * $scale;
  }
  @include breakpoint(mobile-xl) {
    @each $property in $properties {
      #{$property}: width(gutter-m) * $scale;
    }
  }
  @include breakpoint(desktop) {
    @each $property in $properties {
      #{$property}: width(gutter-l) * $scale;
    }
  }
}

// hide from visible display but remain available for screen readers
// (https://developer.paciellogroup.com/blog/2012/05/html5-accessibility-chops-hidden-and-aria-hidden/)
@mixin offscreen {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

// generate responsive font sizes
@mixin responsive-font-size($responsive-font-size) {
  $responsive-font-size: responsive-font-size($responsive-font-size);
  @if $responsive-font-size {
    font-size: nth($responsive-font-size, 1);
    @include breakpoint(mobile) {
      font-size: nth($responsive-font-size, 2);
    }
    @include breakpoint(mobile-xl) {
      font-size: nth($responsive-font-size, 3);
    }
    @include breakpoint(tablet) {
      font-size: nth($responsive-font-size, 4);
    }
    @include breakpoint(desktop) {
      font-size: nth($responsive-font-size, 5);
    }
  }
}

@mixin selection-transparent {
  &::selection {
    background: transparent;
  }
}

// generate unbounded dots
// * requires both row and column inputs
// * $row is the number of dots across and $column is the number of dots down
@mixin unbounded-dots-grid($row, $column) {
  height: $column * width(unbounded-dot);
  width: $row * width(unbounded-dot);
}

// target elements without a class or with the class 'vanilla'
@mixin vanilla {
  &.vanilla,
  &[class=''],
  &:not([class]) {
    @content;
  }
}

// generate media queries for targeting heights
@mixin vertical-breakpoint($min-height, $max-height: null, $use-named-heights: true) {
  @if ($use-named-heights) {
    $min-height: height($min-height);
    @if ($max-height) {
      $max-height: height($max-height);
    }
  }
  @if ($max-height) {
    $max-height: $max-height - 1;
    @media only screen and (min-height: $min-height) and (max-height: $max-height) {
      @content;
    }
  }
  @else {
    @media only screen and (min-height: $min-height) {
      @content;
    }
  }
}
